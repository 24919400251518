// vars

let $anchorTab = $('.anchor-tab'),
		$mainTabLink = $('.card__tabnav a'),
		$mainTabPane = $('.tab__pane'),
		$stepperInput = $('.card__stepper input'),
		$deliveryTabLink = $('.delivery__tabnav a'),
		$deliveryTabPane = $('.delivery__pane'),
		$mapList = $('.delivery__maplist'),
		$notice = $('.notice'),
		$deliveryInnerNavLink = $('.delivery__innernav button'),
		$deliveryMapListLink = $('.delivery__maplist .btnz'),
		$reserveButton = $('.reserve.auth'),
		$pricesButton = $('.card__prices__price');

$('.reserve').click(function(e){
	e.preventDefault();
});

jQuery.fn.outerHTML = function(s) {
		return s
				? this.before(s).remove()
				: jQuery("<p>").append(this.eq(0).clone()).html();
};


let plashWidth = $('.card__prices__price').outerWidth();



$('.plash').width(plashWidth)

$pricesButton.on('mouseup', (e) => {
	setTimeout(() => {
		if ($('.card__prices .mCSB_container').position().left < 0) {
			$('.rozn').addClass('asd');
		} else {
			$('.rozn').removeClass('asd');
		}
	}, 50);

})

$pricesButton.on('click', (e) => {
	e.preventDefault();

	$pricesButton.removeClass('active');
	$(e.currentTarget).addClass('active');

	$('.rozn').removeClass('pls');

	let plashWidth = $('.card__prices__price.active').outerWidth();


	let offsetParent = $('.card__prices').offset().left;

	let offset = Math.abs(offsetParent - $(e.currentTarget).offset().left);

	$('.plash').width(plashWidth)
	$('.plash').css('left', offset)

	priceTwo = parseInt($('.card__prices__price.active .value span').text(), 10);

	if ($(e.currentTarget).hasClass('rozn')) {
		$stepperInput.val(1);
		count = 1;

		if (!/iPhone|iPad|iPod/i.test(navigator.userAgent)) {
			$stepperInput.css('width', $stepperInput.val().length + 1 + 'ex');
		} else {
			$stepperInput.css('width', $stepperInput.val().length + 2 + 'ex');
		}

		averagePriceTwo(priceTwo, count);
	}

	if ($(e.currentTarget).hasClass('ten')) {
		$stepperInput.val(10);
		count = 10;

		if (!/iPhone|iPad|iPod/i.test(navigator.userAgent)) {
			$stepperInput.css('width', $stepperInput.val().length + 1 + 'ex');
		} else {
			$stepperInput.css('width', $stepperInput.val().length + 2 + 'ex');
		}

		averagePriceTwo(priceTwo, count);
	}

	if ($(e.currentTarget).hasClass('thirty')) {
		$stepperInput.val(30);
		count = 30;

		if (!/iPhone|iPad|iPod/i.test(navigator.userAgent)) {
			$stepperInput.css('width', $stepperInput.val().length + 1 + 'ex');
		} else {
			$stepperInput.css('width', $stepperInput.val().length + 2 + 'ex');
		}

		averagePriceTwo(priceTwo, count);
	}

	if (count > 1) {
		$('.btn--down').css('pointer-events', 'auto');
	} else {
		$('.btn--down').css('pointer-events', 'none');
	}

	averagePriceTwo(priceTwo, count);
});

$reserveButton.each(function(){
	let flag = 0;
	$(this).on('click', function(e){
		e.preventDefault();

		if (flag == 0) {
			$(this).html('<span>Товар в резерве</span>');
			$(this).parents('.table__button').find('.onreserve').addClass('visible');
			flag = 1;
		} else {
			document.location.href = '';
			flag = 0;
		}
	});
});

// $('.card__slidertop .slide-wrapper').slick({
// 	slidesToShow: 1,
// 	slidesToScroll: 1,
// 	arrows: false,
// 	asNavFor: '.card__slidernav .slide-wrapper'
// });

// $('.card__slidernav .slide-wrapper').slick({
// 	slidesToShow: 5,
// 	slidesToScroll: 1,
// 	// asNavFor: '.card__slidertop .slide-wrapper',
// 	dots: false,
// 	vertical: true,
// 	nextArrow: $('.next--btn'),
// 	verticalSwiping: true,
// 	focusOnSelect: true,
// 	responsive: [
// 		{
// 			breakpoint: 1260,
// 			settings: {
// 				slidesToShow: 6,
// 			}
// 		},
// 		{
// 			breakpoint: 991,
// 			settings: {
// 				slidesToShow: 5,
// 				vertical: false,
// 			}
// 		},
// 		{
// 			breakpoint: 767,
// 			settings: {
// 				slidesToShow: 4,
// 				vertical: false,
// 			}
// 		},
// 	]
// });

// $('.modal__slidertop .slide-wrapper').slick({
// 	slidesToShow: 1,
// 	slidesToScroll: 1,
// 	infinite: true,
// 	asNavFor: '.modal__slidernav .slide-wrapper',
// 	nextArrow: $('.modal__next'),
// 	prevArrow: $('.modal__prev'),
// });

// $('.modal__slidernav .slide-wrapper').slick({
// 	slidesToShow: 8,
// 	slidesToScroll: 1,
// 	infinite: true,
// 	asNavFor: '.modal__slidertop .slide-wrapper',
// 	dots: false,
// 	vertical: true,
// 	nextArrow: $('.nxt--btn'),
// 	verticalSwiping: true,
// 	focusOnSelect: true,
// 	responsive: [
// 		{
// 			breakpoint: 1260,
// 			settings: {
// 				slidesToShow: 8,
// 			}
// 		},
// 		{
// 			breakpoint: 991,
// 			settings: {
// 				slidesToShow: 10,
// 				vertical: false,
// 			}
// 		},
// 		{
// 			breakpoint: 767,
// 			settings: {
// 				slidesToShow: 4,
// 				vertical: false,
// 			}
// 		},
// 	]
// });

// $('.modal__slidertop .modal__next').on('click', (e) => {
// 	$('.modal__slidernav .slide-wrapper').slick('slickGoTo', $('.modal__slidertop .slick-current').index());
// });

// $('.modal__slidertop .modal__prev').on('click', (e) => {
// 	$('.modal__slidernav .slide-wrapper').slick('slickGoTo', $('.modal__slidertop .slick-current').prev().prev().index());
// });


// // // $('.modal__slidernav').on('click', '.slide', (e) => {
// // // 	let index = parseInt($(e.currentTarget).attr('data-slick-index'));
// // // 	$('.modal__slidertop .slide-wrapper').slick('slickGoTo', index);
// // // });

// $('.modal__slidernav .slide-wrapper').on('beforeChange', function (event, slick, currentSlide, nextSlide) {
// 	$('.modal__slidertop .slide-wrapper').slick('slickGoTo', nextSlide);
// });


$('.card__slidernav').on('click', '.slide', (e) => {
	e.preventDefault();
	if (!$(e.currentTarget).hasClass('video-slide')) {
		$('.card__slidernav .slide').removeClass('active');
		$(e.currentTarget).addClass('active');
		let index = parseInt($(e.currentTarget).attr('data-index'));
		$('.card__slidertop .slide').removeClass('visible');
		setTimeout((e) => {
			$('.card__slidertop .slide').removeClass('active');
		}, 150);
		setTimeout((e) => {
			$(`.card__slidertop .slide[data-index="${index}"]`).addClass('active');
		}, 200);
		setTimeout((e) => {
			$(`.card__slidertop .slide[data-index="${index}"]`).addClass('visible');
		}, 250);
	}
});

$('.modal__slidernav').on('click', '.slide', (e) => {
	e.preventDefault();
	if (!$(e.currentTarget).hasClass('video-slide')) {
		$('.modal-video-block').hide();
		$('.modal__slidernav .slide').removeClass('active');
		$(e.currentTarget).addClass('active');
		let index = parseInt($(e.currentTarget).attr('data-index'));
		$('.modal__slidertop .slide').removeClass('visible');
		setTimeout((e) => {
			$('.modal__slidertop .slide').removeClass('active');
		}, 150);
		setTimeout((e) => {
			$(`.modal__slidertop .slide[data-index="${index}"]`).addClass('active');
		}, 200);
		setTimeout((e) => {
			$(`.modal__slidertop .slide[data-index="${index}"]`).addClass('visible');
		}, 250);
	}
});

$('.modal__slidertop').on('click', '.modal__next', (e) => {
	e.preventDefault();
	$('.modal-video-block').hide();
	let index = $('.modal__slidernav .slide.active').next().attr('data-index');
	$('.modal__slidernav .slide').removeClass('active');
	if (!index) {
		index = 1;
	}

	$(`.modal__slidernav .slide[data-index="${index}"]`).addClass('active');
	$('.modal__slidertop .slide').removeClass('visible');
	setTimeout((e) => {
		$('.modal__slidertop .slide').removeClass('active');
	}, 150);
	setTimeout((e) => {
		$(`.modal__slidertop .slide[data-index="${index}"]`).addClass('active');
	}, 200);
	setTimeout((e) => {
		$(`.modal__slidertop .slide[data-index="${index}"]`).addClass('visible');
	}, 250);
});

$('.modal__slidernav .video-slide').on('click', function(){
	$('.modal-video-block').show();
	$('.modal-video-block').find('iframe').attr('src', $('.modal-video-block').find('iframe').attr('data-src'));
});

$('.modal__slidertop').on('click', '.modal__prev', (e) => {
	$('.modal-video-block').hide();
	e.preventDefault();
	let index = $('.modal__slidernav .slide.active').prev().attr('data-index');
	
	$('.modal__slidernav .slide').removeClass('active');
	if (!index) {
		index = $(e.currentTarget).parents('.modal').find('.modal__slidernav .slide').not('.video-slide').length;
	}

	$(`.modal__slidernav .slide[data-index="${index}"]`).addClass('active');
	$('.modal__slidertop .slide').removeClass('visible');
	setTimeout((e) => {
		$('.modal__slidertop .slide').removeClass('active');
	}, 150);
	setTimeout((e) => {
		$(`.modal__slidertop .slide[data-index="${index}"]`).addClass('active');
	}, 200);
	setTimeout((e) => {
		$(`.modal__slidertop .slide[data-index="${index}"]`).addClass('visible');
	}, 250);
});

var interestingSlider = new Swiper('.interesting-slider', {
	speed: 400,
	slidesPerView: 2,
	slidesPerGroup: 2,
	loop: true,
	navigation: {
		nextEl: '.next',
		prevEl: '.prev',
	},
	pagination: {
		el: '.pag-6',
		type: 'bullets',
		clickable: true,
	},
	breakpoints: {
		768: {
			slidesPerView: 3,
			slidesPerGroup: 3,
			spaceBetween: 30,
		},
		992: {
			slidesPerView: 4,
			slidesPerGroup: 4,
			spaceBetween: 30,
		},
	}
});

var viewedSlider = new Swiper('.viewed-slider', {
	speed: 400,
	slidesPerView: 2,
	slidesPerGroup: 2,
	loop: true,
	navigation: {
		nextEl: '.next-1',
		prevEl: '.prev-1',
	},
	pagination: {
		el: '.pag-5',
		type: 'bullets',
		clickable: true,
	},
	breakpoints: {
		768: {
			slidesPerView: 3,
			slidesPerGroup: 3,
			spaceBetween: 30,
		},
		992: {
			slidesPerView: 4,
			slidesPerGroup: 4,
			spaceBetween: 30,
		},
	}
});

$('.product__title').dotdotdot();

$anchorTab.on('click', (e) => {
	e.preventDefault();

	if ($(window).width() > 767) {
		let href = $(e.currentTarget).attr('href');

		let offset = $(href).offset().top;

		$mainTabLink.removeClass('tabnav--active');
		$(`.card__tabnav a[href="${href}"]`).addClass('tabnav--active');

		$mainTabPane.removeClass('active');
		$(href).addClass('active');

		$('html, body').animate({
			scrollTop: offset - $('.card__tabnav').height() - 15,
		}, 500);
	} else {
		let href = $(e.currentTarget).attr('href');
		let offset = $('.card__tabcontent').offset().top - 30;

		$('html, body').animate({
			scrollTop: offset
		}, 500);

		if (href == '#nal') {
			$('.card__tabcontent').slick('slickGoTo', 1);
		} else if (href == '#deliv') {
			$('.card__tabcontent').slick('slickGoTo', 2);
		} else if (href == '#services') {
			$('.card__tabcontent').slick('slickGoTo', 3);
		}


	}


});

// $('#deliv').ind

$stepperInput.on('focus', function () {
	$(this).parents('.card__stepper').addClass('focused');
});

$stepperInput.on('blur', function () {
	$(this).parents('.card__stepper').removeClass('focused');
});


$deliveryTabLink.on('click', function (e) {
	e.preventDefault();
	let href = $(this).attr('href');
	$deliveryTabLink.removeClass('active');
	$(this).addClass('active');

	$deliveryTabPane.removeClass('active-pane');
	$(href).addClass('active-pane')


	if ($(window).width() < 993) {
		$('.card__tabcontent').slick('setPosition');
	}
});

if ($(window).width() >= 992) {
	$mapList.mCustomScrollbar({
		mouseWheelPixels: 200,
		scrollInertia: 100,
	});
	$('.modal__slidernav .slide-wrapper').mCustomScrollbar({
		mouseWheelPixels: 200,
		scrollInertia: 100,
	});
}



function slides() {
	let element = $('.card__slidernav .slide').length;

	if ($('.video-slide')) {
		if ($(window).width() >= 1260) {
			if (element > 5) {
				$('.card__slidernav').append(`<button class="modal__btn es" data-href="slider-modal1">Еще ${element - 5}</button>`)
			}
		} else if ($(window).width() >= 992 && $(window).width() < 1260) {
			if (element > 6) {
				$('.card__slidernav').append(`<button class="modal__btn es" data-href="slider-modal1">Еще ${element - 6}</button>`)
			}
		} else if ($(window).width() >= 768 && $(window).width() < 992) {
			if (element > 5) {
				$('.card__slidernav .slide:nth-child(5)').css('opacity', '1').css('visibility', 'hidden');
				$('.card__slidernav').append(`<button class="modal__btn es" data-href="slider-modal1">Еще ${element - 5}</button>`)
			}
		} else if ($(window).width() < 768) {
			if (element > 4) {
				$('.card__slidernav .slide:nth-child(5)').css('opacity', '1').css('visibility', 'hidden');
				$('.card__slidernav').append(`<button class="modal__btn es" data-href="slider-modal1">Еще ${element - 4}</button>`)
			}
		}
	} else {
		if ($(window).width() >= 1260) {
			if (element > 5) {
				$('.card__slidernav').append(`<button class="modal__btn es" data-href="slider-modal">Еще ${element - 5}</button>`)
			}
		} else if ($(window).width() >= 992 && $(window).width() < 1260) {
			if (element > 6) {
				$('.card__slidernav').append(`<button class="modal__btn es" data-href="slider-modal">Еще ${element - 6}</button>`)
			}
		} else if ($(window).width() >= 768 && $(window).width() < 992) {
			if (element > 5) {
				$('.card__slidernav .slide:nth-child(5)').css('opacity', '1').css('visibility', 'hidden');
				$('.card__slidernav').append(`<button class="modal__btn es" data-href="slider-modal">Еще ${element - 5}</button>`)
			}
		} else if ($(window).width() < 768) {
			if (element > 4) {
				$('.card__slidernav .slide:nth-child(5)').css('opacity', '1').css('visibility', 'hidden');
				$('.card__slidernav').append(`<button class="modal__btn es" data-href="slider-modal">Еще ${element - 4}</button>`)
			}
		}
	}
	

}

slides()


$('.search-cont ul').mCustomScrollbar({
	mouseWheelPixels: 200,
	scrollInertia: 100,
});

if ($('.card').length > 0) {
	var myMap5;

	function init() {
		let arr = [];

		var myMap2 = new ymaps.Map("map2", {
			center: [55.76, 37.64],
			zoom: 7,
		});

		var myPlacemark1 = new ymaps.Placemark([55.76, 37.64], {}, {
			iconLayout: 'default#image',
			iconImageHref: 'img/pin.svg',
			iconImageSize: [36, 36],
		});

		var myMap3 = new ymaps.Map("map3", {
			center: [55.76, 37.64],
			zoom: 7
		});

		var myMap4 = new ymaps.Map("map4", {
			center: [55.76, 37.64],
			zoom: 7
		});

		var myMap10 = new ymaps.Map("map5", {
			center: [55.76, 37.64],
			zoom: 7,
		});

		var myMap6 = new ymaps.Map("map6", {
			center: [55.76, 37.64],
			zoom: 7,
		});

		var myMap7 = new ymaps.Map("map7", {
			center: [55.76, 37.64],
			zoom: 7,
		});

		myMap2.geoObjects.add(myPlacemark1);



		$deliveryTabLink.bind({
			click: function () {
				if (!myMap5) {
					myMap5 = new ymaps.Map("map-del", {
						center: [55.76, 37.64],
						zoom: 7
					}, {
						searchControlProvider: 'yandex#search'
					});
				} else {
					myMap5.destroy();
					myMap5 = null;
				}
			}
		});

		// myMap5 = new ymaps.Map("map-del", {
		// 	center: [55.76, 37.64],
		// 	zoom: 7
		// });

		var myMap = new ymaps.Map("map", {
			center: [55.76, 37.64],
			zoom: 7
		});

		var mobMap = new ymaps.Map("mob-map", {
			center: [55.76, 37.64],
			zoom: 7
		});

		var mobMap1 = new ymaps.Map("mob-map1", {
			center: [55.76, 37.64],
			zoom: 7
		});

		var mobMap2 = new ymaps.Map("mob-map2", {
			center: [55.76, 37.64],
			zoom: 7
		});

		function markers() {

			myMap.setCenter([55.76, 37.64], 7, {
				checkZoomRange: true
			});

			myMap.geoObjects.removeAll()

			arr = [];
			let i = 0;

			$('.delivery__maplist .btnz:visible').each(function () {
				let coords = $(this).attr('data-coords');

				let id = $(this).attr('data-id');

				let coordsArr = coords.split(',');

				let result = coordsArr.map(function (x) {
					return Number(x);
				});

				arr.push('placemark' + i);
				i++;

				let settings;

				if ($(this).attr('data-type') == 'marker') {
					settings = {
						iconLayout: 'default#image',
						iconImageHref: 'img/kenguru_pickup.png',
						iconImageSize: [53, 53],
					}
				} else {
					settings = {
						iconLayout: 'default#image',
						iconImageHref: 'img/boxberry_pickup.png',
						iconImageSize: [53, 53],
					}
				}


				arr[i] = new ymaps.Placemark(result, {
					'id': id,
					// Зададим содержимое заголовка балуна.
					balloonContentHeader: '<a href = "#">Рога и копыта</a><br>' +
						'<span class="description">Сеть кинотеатров</span>',
					// Зададим содержимое основной части балуна.
					balloonContentBody: '<a href="tel:+7-123-456-78-90">+7 (123) 456-78-90</a><br/>' +
						'<b>Ближайшие сеансы</b> <br/> Сеансов нет.',
					// Зададим содержимое нижней части балуна.
					balloonContentFooter: 'Информация предоставлена:<br/>OOO "Рога и копыта"',
					// Зададим содержимое всплывающей подсказки.
					hintContent: 'Рога и копыта'
				}, settings);


				myMap.geoObjects.add(arr[i]);

				delete arr[0];
			});
		}


		function markersMobile() {

			mobMap.setCenter([55.76, 37.64], 7, {
				checkZoomRange: true
			});

			mobMap.geoObjects.removeAll()

			arr = [];
			let i = 0;

			let $parent = $('.tab__delivery').not('.slick-cloned')

			$parent.find('.delivery__maplist .btnz:visible').each(function () {
				let coords = $(this).attr('data-coords');


				let id = $(this).attr('data-id');

				let coordsArr = coords.split(',');

				let result = coordsArr.map(function (x) {
					return Number(x);
				});

				arr.push('placemark' + i);
				i++;

				let settings;

				if ($(this).attr('data-type') == 'marker') {
					settings = {
						iconLayout: 'default#image',
						iconImageHref: 'img/kenguru_pickup.png',
						iconImageSize: [53, 53],
					}
				} else {
					settings = {
						iconLayout: 'default#image',
						iconImageHref: 'img/boxberry_pickup.png',
						iconImageSize: [53, 53],
					}
				}


				arr[i] = new ymaps.Placemark(result, {
					'id': id,
					// Зададим содержимое заголовка балуна.
					balloonContentHeader: '<a href = "#">Рога и копыта</a><br>' +
						'<span class="description">Сеть кинотеатров</span>',
					// Зададим содержимое основной части балуна.
					balloonContentBody: '<a href="tel:+7-123-456-78-90">+7 (123) 456-78-90</a><br/>' +
						'<b>Ближайшие сеансы</b> <br/> Сеансов нет.',
					// Зададим содержимое нижней части балуна.
					balloonContentFooter: 'Информация предоставлена:<br/>OOO "Рога и копыта"',
					// Зададим содержимое всплывающей подсказки.
					hintContent: 'Рога и копыта'
				}, settings);


				mobMap.geoObjects.add(arr[i]);

				delete arr[0];
			});
		}

		if ($(window).width() >= 768) {
			markers();

			myMap.events.add('boundschange', function () {
				let res = ymaps.geoQuery(myMap.geoObjects)

				var visibleObjects = res.searchInside(myMap).addToMap(myMap);

				let visibleArray = visibleObjects._objects

				let $item = $('.delivery__maplist .btnz');
				$item.parents('li').hide();


				$item.parents('li').removeClass('visible');
				for (let i = 0; i < visibleArray.length; i++) {
					let id = visibleArray[i].properties._data.id;

					$('.delivery__maplist .btnz[data-id="' + id + '"]').parents('li').show();

					$('.delivery__maplist .btnz[data-id="' + id + '"]').parents('li').addClass('visible');
				}

				if (!visibleArray.length) {
					$notice.show();
				} else {
					$notice.hide();
				}
			});

			$deliveryMapListLink.on('click', function (e) {
				e.preventDefault();

				$deliveryMapListLink.removeClass('actived')
				$(this).addClass('actived');

				let id = $(this).parents('li').index('.visible');

				id = parseInt(id);

				id = id + 1;

				let coords = $(this).attr('data-coords');

				let coordsArr = coords.split(',');

				let result = coordsArr.map(function (x) {
					return Number(x);
				});


				myMap.balloon.close();


				myMap.setCenter(result, 18, {
					checkZoomRange: true
				}).then(function () {
					setTimeout(function () {
						arr[id].balloon.open();
					}, 300);

				});
			});
		} else {
			markersMobile();
		}


		$deliveryInnerNavLink.on('click', function (e) {
			e.preventDefault();

			if ($(window).width() >= 768) {
				let label = $(this).attr('data-label');

				$deliveryInnerNavLink.removeClass('active');
				$(this).addClass('active');

				let $item = $('.delivery__maplist .btnz');

				let filtered = $item.filter(label);

				$item.parents('li').hide();
				$item.parents('li').removeClass('visible');

				filtered.parents('li').show();
				filtered.parents('li').addClass('visible');

				markers()
			} else {
				let label = $(this).attr('data-label');


				$deliveryInnerNavLink.removeClass('active');
				$(this).addClass('active');

				let $parent = $(this).parents('.tab__pane')



				let $item = $parent.find('.delivery__maplist .btnz');

				let filtered = $item.filter(label);


				$parent.find('.delivery__maplist ul').not('.slick-dots').slick('unslick');



				if ($parent.find('.delivery__maplist li.visible').parents('.slide')) {
					$parent.find('.delivery__maplist li.visible').unwrap().unwrap();
				}

				$item.parents('li').hide();
				$item.parents('li').removeClass('visible');

				filtered.parents('li').show();
				filtered.parents('li').addClass('visible');





				var items$ = $parent.find('.delivery__maplist li.visible');
				for (var i = 0; i < items$.length; i = i + 3) {
					items$.slice(i, i + 3).wrapAll('<div class="slide"></div>');
				}



				$parent.find('.delivery__maplist .slide').wrapAll('<ul></ul>');

				let wrap = $('.card__tabcontent')[0];

				wrap.slick.setOption({
					swipe: true
				});

				setTimeout(function () {
					$parent.find('.delivery__maplist ul').slick({
						slidesToShow: 1,
						slidesToScroll: 1,
						arrows: false,
						adaptiveHeight: true,
						infinite: false,
						dots: true,
					}).on('afterChange', function (event, slick) {
						wrap.slick.setOption({
							swipe: true
						})
					});

				}, 200);




				markersMobile()
			}


		});
	}

	ymaps.ready(init);
}


let price = parseInt($('.card__price').text(), 10)
let oldPrice = parseInt($('.card__oldprice span').text(), 10)

let averagePrice = function(count) {


	let oldSumm = oldPrice * count;


	let summ = price * count;

	let SummString = String(summ);
	SummString = SummString.replace(/(\d)(?=(\d\d\d)+([^\d]|$))/g, '$1 ');

	let oldSummString = String(oldSumm);
	oldSummString = oldSummString.replace(/(\d)(?=(\d\d\d)+([^\d]|$))/g, '$1 ');

	let average = summ / count;

	average = average.toFixed(0);


	let preSum = summ - average;


	if (count == 1) {
		average = 1;
	}
	$('.card__average span').text(average);

	if (count > 1) {
		$('.first__price span').text(SummString);
		$('.card__oldprice span').text(oldSummString)
		$('.first__average').css('width', 'auto');
		$('.first__average').css('height', 'auto');
		$('.second__average').css('width', 'auto');
		$('.second__average').css('height', 'auto');
		$('.card__prepayment').addClass('act');
	} else {
		$('.first__price span').text(price);
		$('.first__average').css('width', '0');
		$('.first__average').css('height', '0');
		$('.second__average').css('width', 'auto');
		$('.second__average').css('height', 'auto');
		$('.card__prepayment').removeClass('act');
	}

};

let priceTwo = parseInt($('.card__prices__price.active').text(), 10);

let averagePriceTwo = function (priceTwo, count) {


	let summ = priceTwo * count;



	let SummString = String(summ);
	SummString = SummString.replace(/(\d)(?=(\d\d\d)+([^\d]|$))/g, '$1 ');

	let average = summ / count;

	average = average.toFixed(0);

	if (count == 1) {
		average = 1;
	}
	$('.second__average span').text(average);

	if (count > 1) {
		$('.second__average').html(`За ${count} шт – <span>${SummString}</span> &#8381;`)
		$('.second__average').css('width', 'auto');
		$('.first__average').css('height', 'auto');
		$('.second__average').css('height', 'auto');
		$('.first__average').css('width', 'auto');
		$('.card__prepayment').addClass('act');
	} else {
		$('.second__price span').text(price);
		$('.second__average').css('width', '0');
		$('.first__average').css('height', '0');
		$('.second__average').css('height', '0');
		$('.first__average').css('width', '0');
		$('.card__prepayment').removeClass('act');
	}



	if($('.plash').length !== 0) {



		if (count >= 1 && count < 10) {
			$pricesButton.removeClass('active');
			$('.rozn').addClass('active');
			let plashWidth = $('.card__prices__price.active').outerWidth();




			let offsetParent = $('.card__prices').offset().left;

			let offset = 0;




			if ($('.card__prices .mCSB_container').length > 0) {
				offset = Math.abs(offsetParent - $('.rozn').offset().left + $('.card__prices .mCSB_container').position().left);
			} else {
				offset = Math.abs(offsetParent - $('.rozn').offset().left);
			}


			$('.plash').width(plashWidth)
			$('.plash').css('left', offset)
		}

		if (count >= 10) {
			$pricesButton.removeClass('active');
			$('.ten').addClass('active');
			let plashWidth = $('.card__prices__price.active').outerWidth();





			let offsetParent = $('.card__prices').offset().left;

			let offset = 0;

			if ($('.card__prices .mCSB_container').length > 0) {
				offset = Math.abs(offsetParent - $('.ten').offset().left + $('.card__prices .mCSB_container').position().left);
			} else {
				offset = Math.abs(offsetParent - $('.ten').offset().left);
			}

			$('.plash').width(plashWidth)
			$('.plash').css('left', offset)
		}

		if (count >= 30) {
			$pricesButton.removeClass('active');
			$('.thirty').addClass('active');
			let plashWidth = $('.card__prices__price.active').outerWidth();


			let offsetParent = $('.card__prices').offset().left;

			let offset = 0;

			if ($('.card__prices .mCSB_container').length > 0) {
				offset = Math.abs(offsetParent - $('.thirty').offset().left + $('.card__prices .mCSB_container').position().left);
			} else {
				offset = Math.abs(offsetParent - $('.thirty').offset().left);
			}

			$('.plash').width(plashWidth)
			$('.plash').css('left', offset)
		}
	}



};

function setPriceWidth() {

	let flag = 0;
	$('.third__price').width('auto')

	$('.third__price').each(function(){
		let width = $(this).outerWidth();


		if (width > flag) {
			flag = width;
		}



	});

	$('.third__price').width(flag)
}

let averagePriceThree = function (count) {



	let priceThree = 0;
	let summ = 0;

	$('.third__price').each(function(){
		priceThree = $(this).parents('.card__price__block').attr('data-price');
		priceThree = priceThree.replace(/\s+/g, '');
		priceThree = parseInt(priceThree, 10)


		summ = priceThree * count;


		let SummString = String(summ);
		SummString = SummString.replace(/(\d)(?=(\d\d\d)+([^\d]|$))/g, '$1 ');

		$(this).find('span').text(SummString)


		let average = summ / count;

		average = average.toFixed(0);


		if (count == 1) {
			average = 1;
		}

		$(this).parents('.card__price__block').find('.third__average span').text(average);

		if (count > 1) {
			$(this).find('span').text(SummString)
			$('.third__average').css('height', '16px');
			$('.card__prepayment').addClass('act');
		} else {
			$(this).find('span').text(priceThree)
			$('.third__average').css('height', '0');
			$('.card__prepayment').removeClass('act');
		}

		setPriceWidth()
	});



};

let count = $stepperInput.val();

$('.card__stepper .btn--down').css('pointer-events', 'none');

$('.card').find($stepperInput).on('keyup', function () {

	if ($(this).val() == '0') {
		$(this).val(1);
	}

	if (!/iPhone|iPad|iPod/i.test(navigator.userAgent)) {
		$(this).css('width', $(this).val().length + 1 + 'ex');
	} else {
		$(this).css('width', $(this).val().length + 2 + 'ex');
	}
	count = $stepperInput.val();

	averagePrice(count);
	averagePriceTwo(priceTwo, count)
	averagePriceThree(count);


	if (count == 1) {
		$('.card__stepper .btn--down').css('pointer-events', 'none');
	} else {
		$('.card__stepper .btn--down').css('pointer-events', 'auto');
	}

});

$stepperInput.on('keypress', function(event) {
	event = event || window.event;

	if (event.charCode && event.charCode != 0 && event.charCode != 46 && (event.charCode < 48 || event.charCode > 57))
		return false;

	averagePrice(count);
	averagePriceTwo(priceTwo, count)
	averagePriceThree(count);
});

$stepperInput.on('change', function (event) {
	averagePrice(count);
	averagePriceTwo(priceTwo, count)
	averagePriceThree(count);

	if (!$(this).val()) {
		$(this).val(1);
	}

	count = $stepperInput.val();


	if (count == 1) {
		$('.card__stepper .btn--down').css('pointer-events', 'none');
	} else {
		$('.card__stepper .btn--down').css('pointer-events', 'auto');
	}

});

$('.card__stepper .btn--up').on('click', function(e){
	e.preventDefault();
	count++;

	if (count == 1) {
		$('.card__stepper .btn--down').css('pointer-events', 'none');
	} else {
		$('.card__stepper .btn--down').css('pointer-events', 'auto');
	}

	averagePrice(count);
	averagePriceTwo(priceTwo, count)
	averagePriceThree(count);

	$(this).parents('.card__stepper').find('input').val(count);
	$(this).parents('.card__stepper').find('input').css('width', $(this).parents('.card__stepper').find('input').val().length + 1 + 'ex');

	if (!/iPhone|iPad|iPod/i.test(navigator.userAgent)) {
		$(this).parents('.card__stepper').find('input').css('width', $(this).parents('.card__stepper').find('input').val().length + 1 + 'ex');
	} else {
		$(this).parents('.card__stepper').find('input').css('width', $(this).parents('.card__stepper').find('input').val().length + 2 + 'ex');
	}
});

$('.card__stepper .btn--down').on('click', function (e) {
	e.preventDefault();
	count--;

	$(this).parents('.card__stepper').find('input').val(count);
	if (!/iPhone|iPad|iPod/i.test(navigator.userAgent)) {
		$(this).parents('.card__stepper').find('input').css('width', $(this).parents('.card__stepper').find('input').val().length + 1 + 'ex');
	} else {
		$(this).parents('.card__stepper').find('input').css('width', $(this).parents('.card__stepper').find('input').val().length + 2 + 'ex');
	}

	averagePrice(count);
	averagePriceTwo(priceTwo, count)
	averagePriceThree(count);

	if (count == 1) {
		$(this).css('pointer-events', 'none');
	} else {
		$(this).css('pointer-events', 'auto');
	}
});


$('.open__map').each(function(){
	let a = 0;
	$(this).click(function (e) {
		e.preventDefault();
		if (a == 0) {
			$(this).parents('tr').addClass('none-border');
			$(this).addClass('rotated');
			$(this).find('span').text('Свернуть карту');
			$(this).parents('tr').next().show();
			a = 1;
		} else {
			$(this).removeClass('rotated');
			$(this).find('span').text('Показать на карте');
			$(this).parents('tr').next().hide();
			$(this).parents('tr').removeClass('none-border');
			a = 0;
		}

	});
});



window.onload = function () {
	setTimeout(function () {
		$('.image-switch-item img').each(function () {
			if ($(this).attr('src') == '#') {
				$(this).attr('src', $(this).attr('data-src'));
			}
		});
	}, 500);
};


$mainTabLink.on('click', function (e) {
	e.preventDefault();
	let href = $(this).attr('href');

	$mainTabLink.removeClass('tabnav--active');
	$(this).addClass('tabnav--active');

	$mainTabPane.removeClass('active');
	$(href).addClass('active');
});


$('.tab__delivery__top input').jcOnPageFilter({
	highlightColor: "transparent",
	textColorForHighlights: "#4d4d4d",
	hideNegatives: false,
});

$('.tab__delivery__top input').on('keyup', function () {
	let $this = $(this);
	let val = $this.val();

	if (val.length >= 1) {
		$('.search-result').fadeIn();
	} else {
		$('.search-result').fadeOut();
	}
});

$('.tab__delivery__top input').on('blur', function () {
	$('.search-result').fadeOut();
});

let fl = 0;

$('.inf').on('click', function(e){
	e.preventDefault();

	if (fl == 0) {
		$(this).addClass('rotated');
		$(this).parents('.delivery__info').find('.hidden-text').show();
		fl = 1;
	} else {
		$(this).removeClass('rotated');
		$(this).parents('.delivery__info').find('.hidden-text').hide();
		fl = 0;
	}

	// $(this).toggleClass('rotated');
	// $(this).parents('.delivery__info').find('.hidden-text').slideToggle();

	if ($(window).width() < 768) {
		$('.card__tabcontent').slick('setPosition');
	}

});

// $('[data-tinting-tar="cart"]').on('click', () => {
// 	resetSearch();
// });

$('body').on('click', '.modal__btn', function(e){
	e.preventDefault();
	let href = $(this).attr('data-href');
	let dataItem = $(this).attr('data-item');

	if ($(`#${href}`).height() > $(window).height()) {
		$('.modal__container').css('align-items', 'flex-start');
	} else {
		$('.modal__container').removeAttr('style');
	}

	if ($(this).attr('data-href') == 'story-article' || $(this).attr('data-href') == 'story-video') {
		$(this).addClass('story-viewed');
		$(`#${href}`).find('iframe').attr('src', $(`#${href}`).find('iframe').attr('data-src'));

		if ($(window).width() <= 767) {
			$(`#${href}`).css('display','flex');
		}
	}

	if ($(this).attr('data-href') == 'tinting-modal') {
		$(`#${href}`).css('display', 'flex');
		$('.tinting-bottom__product').html(`<span>Товар: </span> ${$('.main-title').text()}`);
		$('.tinting-cart-info__product img').attr('src', $('.card__slidertop .slide').eq(0).find('img').attr('src'));
		resetSearch();
		$('.custom-select-tiny').removeClass('is-open');
	}

	if ($(this).attr('data-href') == 'order-tinting-modal') {
		let image = $(this).parents('.filt-item').find('.switched-image').eq(0).find('img').attr('src');
		$(`#${href}`).css('display', 'flex');
		$(`#${href}`).find('.tinting-cart-info__product img').attr('src', image);
	}

	if ($(this).attr('data-href') == 'slider-modal1') {
		$(`#${href}`).css('display','flex');
		$(`#${href}`).find('.modal-video-block').show();
		$('.modal-video-block').find('iframe').attr('src', $('.modal-video-block').find('iframe').attr('data-src'));
		$(`#${href}`).find('.modal__slidernav .slide').removeClass('active');
		$(`#${href}`).find('.modal__slidernav .video-slide').addClass('active');

		$(`#${href}`).find('.mCSB_container').css('left','0');
	}

	if ($(this).attr('data-href') == 'city-modal') {
		if($(window).width() > 767) {
			setTimeout(function(){
				let itemsWidth = 0;
				$('.city-tags__item').each(function(){
					itemsWidth += ($(this).outerWidth() + 9);
				});

				itemsWidth = itemsWidth - 9;
				itemsWidth = Math.trunc(itemsWidth);

				if (itemsWidth > $('.city-tags').outerWidth()) {
					$('.city-tags__item:last-child').hide();
				}
			}, 0);
		}
		
		
	}



	disableScroll();

	if (dataItem) {
		let title = $('.card__heading').text();
		let artikul = $('.card__vendorcode').text();
		let quantity = $('.card__stepper input').val();
		let sum = $('.card__price').html();
		let image = $('.card__slidertop .slick-current').find('img').attr('src');


		$('.item__modal').find('.title').text(title);
		$('.item__modal').find('.art').text(artikul);
		$('.item__modal').find('.quantity span').text(`${quantity} шт`);
		$('.item__modal').find('.summ span').html(sum);
		$('.item__modal').find('.image').html(`<img src="${image}">`);
	}

	$(`#${href}`).show();

	if (href == 'slider-modal') {
		// $('.modal__slidernav .slide-wrapper')[0].slick.refresh();
		// $('.modal__slidertop .slide-wrapper')[0].slick.refresh();

		if ($(window).width() < 993) {
			$(`#${href}`).css('display', 'flex');
			$(".modal__slidernav .mCSB_container").css('left', '0');
		}
	}

	$('.modal__container').addClass('visible');
});

function closeModal() {
	$('.modal__container').removeClass('visible');
	$('.modal').hide();

	$('.mobile__map').hide();

	enableScroll();
}

function disableScroll() {
	let pagePos = $(window).scrollTop();
	$('body').addClass('stop-scroll').attr('data-scroll', pagePos);
	//$('body').css('top', -pagePos);
}

function enableScroll() {
	let pos = parseInt($('body').attr('data-scroll'), 10);

	//$('body').css('top', 'auto');

	$('body').removeClass('stop-scroll').removeAttr('data-scroll');
	window.scrollTo(0, pos);
}

$('.continue').on('click', function(e){
	e.preventDefault();
	closeModal();
});

$('.modal__container').on('click', function (e) {
	if (e.target == this) {
		e.preventDefault();
		closeModal();

		$('.modal').find('iframe').attr('src', $('.modal').find('iframe').attr('src'))
	}
});

$('.modal__close, .modal-close').on('click', function (e) {
	e.preventDefault();
	closeModal();
	$('[data-tinting-tar="choice"]').trigger('click');

	$('.modal').find('iframe').attr('src', $('.modal').find('iframe').attr('src'))
});

$(window).on('resize', function(){
	changeWords()
});

let shopTitleQua = $('.card__available-shop .title span').text();
let stockTitleQua = $('.card__available-stock .title span').text();

function changeWords() {

	if ($(window).width() <= 768) {
		$('.card__available-shop .title').html('В магазинах г. Иваново: ' + shopTitleQua + '');
		$('.card__available-stock .title').html('На складе: ' + stockTitleQua + '');
	}
	if ($(window).width() <= 991) {
		$('.delivery__tabnav li:first-child a span').text('Самовывоз');
	} else {
		$('.delivery__tabnav li:first-child a span').text('Самовывоз из магазина или пункта выдачи');

	}

}

changeWords()

if ($(window).width() <= 991) {
	$(".modal__slidernav .slide-wrapper").mCustomScrollbar({
		axis: "x",
		mouseWheelPixels: 200,
		scrollInertia: 100,
	});
}

if ($(window).width() >= 768 && $(window).width() <= 991) {
	var cx, dx = 0;

	$(".card__prices").mCustomScrollbar({
		axis: "x",
		mouseWheelPixels: 200,
		scrollInertia: 100,
	});

	$(".card__tabnav").mCustomScrollbar({
		axis: "x",
		mouseWheelPixels: 200,
		scrollInertia: 100,
		callbacks: {
			whileScrolling: function () {
				if ($(".card__tabnav ul li:first-child").offset().left < 0) {
					$('.card__tabnav').addClass('over');
				} else {
					$('.card__tabnav').removeClass('over');
				}
			}
		}
	});

	$(".delivery__innernav").mCustomScrollbar({
		axis: "x",
		mouseWheelPixels: 200,
		scrollInertia: 100,
		callbacks: {
			whileScrolling: function () {
				$('.delivery__innernav').removeClass('stop');
				if ($(".delivery__innernav ul li:first-child").offset().left < 0) {
					$('.delivery__innernav').addClass('over');
				} else {
					$('.delivery__innernav').removeClass('over');
				}
			},
			onTotalScroll: function() {
				$('.delivery__innernav').addClass('stop');
			}
		}
	});
}


if ($(window).width() <= 767) {

	$(".services__table").mCustomScrollbar({
		axis: "x",
		mouseWheelPixels: 200,
		scrollInertia: 100
	});

	let wrap = $('.card__tabcontent')[0];


	$('.card__tabcontent').slick({
		slidesToShow: 1,
		slidesToScroll: 1,
		arrows: false,
		adaptiveHeight: true,
		dots: true,
	});


	var items$ = $('.delivery__maplist li.visible');
	for (var i = 0; i < items$.length; i = i + 3) {
		items$.slice(i, i + 3).wrapAll('<div class="slide"></div>');
	}

	setTimeout(function(){
		$('.delivery__maplist ul').slick({
			slidesToShow: 1,
			slidesToScroll: 1,
			arrows: false,
			adaptiveHeight: true,
			infinite: false,
			dots: true,
		}).on('afterChange', function (event, slick) {
			wrap.slick.setOption({
				swipe: true
			})
		});
	}, 200);

	$('.delivery__maplist').on('touchstart', function () {

		wrap.slick.setOption({
			swipe: false
		})
	})
}

$('.reserve-btn').click(function(){
	$(this).parents('.mobile__info__bottom').find('.prim').css('display', 'block');
	$('.card__tabcontent').slick('setPosition');
});

$('.onreserve-btn').click(function () {
	$(this).text('Товар в резерве')
	$(this).parents('.mobile__info__bottom').find('.prim').css('display', 'block');

	$('.card__tabcontent').slick('setPosition');
});

$('.dop__info').click(function(){
	$(this).toggleClass('active');
	$(this).parents('.mobile__info__item').find('.schedule').toggle();
	$(this).parents('.mobile__info__item').find('.reserve').toggle();
	$('.card__tabcontent').slick('setPosition');
});



if (!/iPhone|iPad|iPod/i.test(navigator.userAgent)) {
} else {
	$('.card__stepper').find('input').css('width', $('.card__stepper').find('input').val().length + 2 + 'ex');
}


// catalog js
// vars

function declOfNum(number, titles) {
	let cases = [2, 0, 1, 1, 1, 2];
	return titles[(number % 100 > 4 && number % 100 < 20) ? 2 : cases[(number % 10 < 5) ? number % 10 : 5]];
}

const $showMore = $('.catalog-category__showmore');
const $catalogList = $('.catalog-category__list');
const $bannerSlider = $('.catalog-content .banner-slider__content');
const $mobileBannerSlider = $('.banners-mobile .banner-slider__content')
const $storiesSlider = $('.stories-slider__content');

if ($(window).width() <= 991) {
	let slider = $('.product').not('.filt-item__product').find('.image-switch').slick({
		arrows: false,
		dots: true,
		infinite: false,
	});
	setTimeout(() => {
		$('.product').not('.filt-item__product').find('.image-switch').each(function () {
			if ($(this).find('.image-switch-item').length > 6) {
				$(this).attr('data-length', $(this).find('.image-switch-item').length);
				$(this).slick('unslick');
				$(this).find('.image-switch-item:nth-child(n+7)').remove();
				$(this).css('overflow', 'hidden');
				let th = $(this);
				setTimeout(() => {
					th.slick({
						arrows: false,
						dots: true,
						infinite: false,
					});

					$(this).removeAttr('style');
				}, 100)
			}

			if ($(this).find('.image-switch-item').length == 1) {
				$(this).find('.slick-dots').css('opacity', '0');
			}
		});
	}, 100);


	$('.product').not('.filt-item__product').find('.image-switch').on('beforeChange', function (event, slick, currentSlide, nextSlide) {


		let length = $(this).attr('data-length');


		if (length > 6) {
			if ((nextSlide == ((length - (length - 6)) - 1))) {
				$(this).parents('.product__image-wrapper').addClass('more-photos').addClass('active');
			} else {
				$(this).parents('.product__image-wrapper').removeClass('more-photos').removeClass('active')
			}
		}


	});

	$('.product').not('.filt-item__product').find('.image-switch').on('touchstart', () => {
		$('.viewed-slider').addClass('swiper-no-swiping');
	});

	$('.product').not('.filt-item__product').find('.image-switch').on('afterChange', function (event, slick, direction) {
		$('.viewed-slider').removeClass('swiper-no-swiping');
	});
}

// задаем всем свитчам на странице индексы
$('.image-switch').each(function () {
	let itemsLength = $(this).find('.image-switch-item').length;
	let forMoreLength = $(this).find('.image-switch-item:not(.slick-cloned)').length;

	if (itemsLength <= 1) {
		$(this).parents('.product__image-wrapper').find('.image-pagination').hide();
	}

	for (let i = 0; i < itemsLength; i++) {
		$(this).find('.image-switch-item').eq(i).attr('data-index', i);
		if (i <= 5) {
			$(this).parents('.product__image-wrapper').find('.image-pagination').append('<div class="image-pagination-item" data-index="' + i + '"></div>');
			$(this).parents('.product__image-wrapper').find('.image-pagination').find('.image-pagination-item').eq(0).addClass('image-pagination-item-active');
		}
	}

	if (forMoreLength > 6) {
		$(this).parents('.product__image-wrapper').addClass('more-photos');

		let l = itemsLength - 6;
		$(this).parents('.product__image-wrapper').attr('data-more', `Ещё ${l} фото`);
	}
});

// переключение свитчей по наведению
$('.image-switch-item').on('mouseover', function () {
	let i = $(this).attr('data-index');
	$(this).parents('.product__image-wrapper').find('.image-pagination').find('.image-pagination-item').removeClass('image-pagination-item-active');
	$(this).parents('.product__image-wrapper').find('.image-pagination').find('.image-pagination-item[data-index="' + i + '"]').addClass('image-pagination-item-active');

	if ($(this).attr('data-index') == 5) {
		$(this).parents('.more-photos').addClass('active');
	}
});

// переключение свитчей по наведению
$('.image-switch-item').on('mouseout', function () {
	$(this).parents('.product__image-wrapper').find('.image-pagination').find('.image-pagination-item').removeClass('image-pagination-item-active');
	$(this).parents('.product__image-wrapper').find('.image-pagination').find('.image-pagination-item[data-index="0"]').addClass('image-pagination-item-active');
	$('.more-photos').removeClass('active');
});



// tinting

$('.tinting-images__tab').each(function(){
	$(this).find('img').eq(0).attr('src', $(this).find('img').eq(0).attr('data-src'));
});

$('.tinting-wrap__btns--active').find('.tinting-wrap__btn').each(function(){
	$(this).attr('style', $(this).attr('data-back'));
});

$('.tinting-wrap__btn').on('click', (e) => {
	let self = $(e.currentTarget);
	let path = self.attr('data-path');
	$('.tinting-wrap__btns--active .tinting-wrap__btn').removeClass('tinting-wrap__btn--active');
	self.addClass('tinting-wrap__btn--active');
	$('.tinting-images__tab--active .tinting-images__item').removeClass('tinting-images__item--active');
	$(`.tinting-images__tab--active [data-index="${path}"]`).attr('src', $(`.tinting-images__tab--active [data-index="${path}"]`).attr('data-src'));
	$(`.tinting-images__tab--active [data-index="${path}"]`).addClass('tinting-images__item--active');
});

$('.custom-select-tiny').click(function (e) {
	console.log(e.target)
	if ($(e.target).hasClass('custom-select-tiny') || $(e.target).hasClass('custom-select-tiny__input') || e.target.tagName == 'SPAN') {
		if (!$(this).hasClass('is-open')) {
			$('.custom-select-tiny').removeClass('is-open');
			$(this).addClass('is-open');
		} else {
			$(this).removeClass('is-open');
		}

		console.log('asd1');
	}
	
});

// когда кликаем вне селекта - скрываем и сбрасываем
$(document).mouseup(function (e) { // событие клика по веб-документу
	let select = $('.custom-select-tiny'); // тут указываем ID элемента
	if (!select.is(e.target) && select.has(e.target).length === 0) { // и не по его дочерним элементам
		select.removeClass('is-open');

		console.log('asd2');
	}
});

// клик по элементам селекта
$('.custom-select-tiny-placement .custom-select-tiny__dropdown li').click(function () {
	$(this).parents('.custom-select-tiny').find('.custom-select-tiny__dropdown li').removeClass('is-active');

	let $parent = $(this).parents('.custom-select-tiny');

	$(this).addClass('is-active');
	$parent.find('.custom-select-tiny__input').text($(this).text());

	console.log('asd3');

	$parent.removeClass('is-open');

	roomTabs($(this));
});

$('.custom-select-tiny-collection .custom-select-tiny__dropdown li').click(function () {
	$(this).parents('.custom-select-tiny').find('.custom-select-tiny__dropdown li').removeClass('is-active');

	let $parent = $(this).parents('.custom-select-tiny');

	$(this).addClass('is-active');
	$parent.find('.custom-select-tiny__input').text($(this).text());

	$parent.removeClass('is-open');
});

const roomTabs = (selector) => {
	let target = $(selector).attr('data-room-tabs');
	$('.tinting-images__tab').removeClass('tinting-images__tab--active');
	$('.tinting-wrap__btns').removeClass('tinting-wrap__btns--active').removeClass('tinting-wrap__btns--anim');
	$(`.tinting-images__tab[data-room-target="${target}"]`).addClass('tinting-images__tab--active');
	$(`.tinting-wrap__btns[data-room-btns="${target}"]`).addClass('tinting-wrap__btns--active');
	$('.tinting-wrap__btns--active').find('.tinting-wrap__btn').each(function(){
		$(this).attr('style', $(this).attr('data-back'));
	});
	setTimeout(() => {
		$(`.tinting-wrap__btns[data-room-btns="${target}"]`).addClass('tinting-wrap__btns--anim');
	}, 100);
};

// начальное состояние квадратов цветов
$('.tinting-colors__item').each(function(){
	$(this).css('background-color', `${$(this).attr('data-color')}`);
});

// начальное состояние активных цветов
$('.tinting-active-colors__item').each(function(index){
	let name = $(this).attr('data-name');
	let color = $(this).attr('data-color');
	$(this).find('.tinting-active-colors__color').css('background-color', `${color}`);
	$(this).find('span').text(name);
});

// табы внутри модалки справа - ПЕРЕДЕЛАТЬ!
const $tintingTabBtn = $('.tinting-tab-cross');
const $tintingPrice = $('.tinting-bottom__price span');
let isSlider = false;

// обрабочтик по кнопкам перехода между табами
$tintingTabBtn.on('click', (e) => {
	e.preventDefault();


	// получаем атрибут таба, к которому надо перейти
	let tab = $(e.currentTarget).attr('data-tinting-tar');

	// переходим на таб
	$('.custom-select-tiny').removeClass('is-open');
	let $tintingTab = $('.tinting-tab');
	$tintingTab.removeClass('tinting-tab--active');
	$(`[data-tinting-tab="${tab}"]`).addClass('tinting-tab--active');

	// выбор первого активного элемент
	let $currentTab = $('.tinting-tab--active');
	let $colorElement = $('.tinting-colors__item');
	$colorElement.removeClass('tinting-colors__item--active');
	let $activeColorElement = $($currentTab.find('.tinting-colors__item')[0]);
	$activeColorElement.addClass('tinting-colors__item--active');

	let firstName = $activeColorElement.attr('data-name');
	$('.tinting-cart-info__color span').text(firstName);

	// ставим цену первого активного
	let price = $activeColorElement.attr('data-price');
	$tintingPrice.text(`${price ? String(price).replace(/(\d)(?=(\d\d\d)+([^\d]|$))/g, '$1 ') : '50'} ₽`);

	// установка цвета
	if (!$currentTab.hasClass('tinting-tab--first') && tab !== 'cart') {
		let color = $activeColorElement.attr('data-color');
		$('html')[0].style.setProperty('--tinting-color', color);

		// базовое задание цветов и номеров для активных
		$currentTab.find('.tinting-colors__item').each(function(index){
			if (index < 6) {
				let a = $(this).attr('data-name');
				let b = $(this).attr('data-color');
				let c = $(this).attr('data-price');
				if (c == 'undefined') {
					c = 50;
				}
				$currentTab.find('.tinting-active-colors__item').eq(index).attr('data-name', a).attr('data-color', b).attr('data-price', c);
				$currentTab.find('.tinting-active-colors__item').eq(index).find('.tinting-active-colors__color').css('background-color', `${b}`);
				$currentTab.find('.tinting-active-colors__item').eq(index).find('span').text(a);
			}
		});
	}

	// выбор первого активного в активных
	let name = $activeColorElement.attr('data-name');
	let $activeActiveColorElement = $('.tinting-active-colors__item');
	$activeActiveColorElement.removeClass('tinting-active-colors__item--active');
	$(`.tinting-active-colors__item[data-name="${name}"]`).addClass('tinting-active-colors__item--active');

	if ($currentTab.hasClass('tinting-tab--first')) {
		$('html')[0].style.setProperty('--tinting-color', '#fff');
	}
	
	// запуск слайдера, если не запущен (и если элементов больше 72). 
	// Если нет - скрываем элементы управления и управляем тенью без слайдера
	let colorElementsLength = $currentTab.find('.tinting-colors__item').length;
	let colorsSliderProps = $currentTab.find('.colors-slider-props');

	if (colorElementsLength === 0) {
		if ($('.colors-slider').hasClass('swiper-container-initialized')) {
			colorsSlider.destroy();
		}
	}

	let limitElements = 72;
	const scrollWidth = parseInt(window.innerWidth - document.body.offsetWidth);

	if ($(window).width() >= 992 - scrollWidth) {
		limitElements = 72;
	} else if ($(window).width() < 992 - scrollWidth) {
		limitElements = 36;
	}


	if (colorElementsLength > limitElements) {
		colorsSliderProps.removeClass('color-slider-props--hidden');
		sliderColors();

		isSlider = true;

		// показ тени
		let vanillaJsActiveElement = $activeColorElement[0];
		if (vanillaJsActiveElement) {
			let left = vanillaJsActiveElement.offsetLeft;
			let $selectLine = $('.select-line');
			$selectLine.removeClass('select-line--active');
			$currentTab.find('.swiper-slide-active').find('.select-line').addClass('select-line--active').css('transform', `translateX(${left}px)`);

			if ($(window).width() < 768) {
				$('.select-line').css('width', $currentTab.find('.tinting-colors__item').width() + 10 + `px`)
			}
		}
	} else {
		isSlider = false;
		shadowLineWithoutSlider();
		colorsSliderProps.addClass('color-slider-props--hidden');
	}

	if (!$currentTab.hasClass('tinting-tab--first') && tab !== 'cart') {
		$('.mobile-visible').show();
		$('.tinting-wrap__descr').show();
		$('.tinting-warning').hide();
		$('.colors-top').css('display', 'flex');
		$('.colors-back').css('display', 'inline-block');
		$('.colors-title').text($(e.currentTarget).find('.tinting-choice__text').text() + ' цвета');
	}
	
	if ($currentTab.hasClass('tinting-tab--first')) {
		$('.mobile-visible').show();
		$('.tinting-wrap__descr').show();
		$('.tinting-warning').hide();
		$('.colors-top').css('display', 'flex');
		$('.colors-back').css('display', 'none');
		$('.colors-title').text('Выберите цветовую группу');
	}

	if (tab == 'cart') {
		$('.tinting-wrap__descr').hide();
		$('.mobile-visible').hide();
		$('.tinting-warning').show();
		$('.colors-top').hide();
		$('[data-tinting-tab="cart"]').find('.colors-back').show();
	}
});

// работы тени без слайдера, если элементов меньше
const shadowLineWithoutSlider = () => {
	let $currentTab = $('.tinting-tab--active');
	let $activeColorElement = $currentTab.find('.tinting-colors__item--active')[0];

	if ($activeColorElement) {
		let left = $activeColorElement.offsetLeft;
		$('.select-line').removeClass('select-line--active').css('transform', `translateX(0px)`);
		$($currentTab.find('.select-line')[0]).css('transform', `translateX(${left}px)`).addClass('select-line--active');
	}
};

// загрузка и перезагрузка слайдера в активном табе
let colorsSlider = '';
const sliderColors = () => {
	colorsSlider = new Swiper('.tinting-tab--active .colors-slider', {
		speed: 400,
		slidesPerView: 1,
		loop: true,
		spaceBetween: 30,
		simulateTouch: false,
		navigation: {
			nextEl: '.colors-next',
			prevEl: '.colors-prev',
		},
		pagination: {
			el: '.pag-7',
			type: 'bullets',
			clickable: true,
		},
		breakpoints: {
			768: {
				spaceBetween: 50,
			}
		}
	});

	colorsSlider.on('transitionStart', function () {
		$('.select-line').removeClass('select-line--active').css('transform', `translateX(${0}px)`);
	});

	colorsSlider.on('transitionEnd', function () {
		let item = $('.colors-slider .swiper-slide-active .tinting-colors__item--active')[0];
		let item2 = $('.colors-slider .swiper-slide-dublicate-active .tinting-colors__item--active')[0];
		if (item) {
			let left = item.offsetLeft;
			$('.colors-slider .swiper-slide-active').find('.select-line').css('transform', `translateX(${left}px)`).addClass('select-line--active');
		}

		if (item2) {
			let left = item2.offsetLeft;
			$('.colors-slider .swiper-slide-dublicate-active').find('.select-line').css('transform', `translateX(${left}px)`).addClass('select-line--active');
		}
	});
};

// обработчик клика по квадратам цветов
$('body').on('click', '.tinting-tab--active .tinting-colors__item', function(){
	let self = $(this);
	changeColorsFromTop(self);
});

// обработчик клика по квадратам выбранных цветов
$('body').on('click', '.tinting-tab--active .tinting-active-colors__item', function(){
	let self = $(this);
	changeColorsFromBottom(self);
});

// функция клика по квадратам цветов
const changeColorsFromTop = (selector) => {

	let $currentTab = $(selector).parents('.tinting-tab--active');

	// активность квадратов (в том числе и дубли, если они есть)
	$currentTab.find('.tinting-colors__item').removeClass('tinting-colors__item--active');
	let name = $(selector).attr('data-name');
	$currentTab.find(`.tinting-colors__item[data-name="${name}"]`).addClass('tinting-colors__item--active');
	$('.tinting-cart-info__color span').text(name);

	// активность в активных
	$currentTab.find('.tinting-active-colors__item').removeClass('tinting-active-colors__item--active');
	$currentTab.find(`.tinting-active-colors__item[data-name="${name}`).addClass('tinting-active-colors__item--active');

	// движение теневой полосы
	let left = $(selector)[0].offsetLeft;
	$('.select-line').removeClass('select-line--active');
	if (isSlider) {
		$currentTab.find('.swiper-slide-active').find('.select-line').addClass('select-line--active').css('transform', `translateX(${left}px)`);
	} else {
		$currentTab.find('.select-line').addClass('select-line--active').css('transform', `translateX(${left}px)`);
	}
	
	// переключение цены
	let price = $(selector).attr('data-price');
	$tintingPrice.text(`${price ? String(price).replace(/(\d)(?=(\d\d\d)+([^\d]|$))/g, '$1 ') : '50'} ₽`);

	// смена цвета
	let color = $(selector).attr('data-color');
	$('html')[0].style.setProperty('--tinting-color', color);

	// меняются элементы в активных

	$currentTab.find('.tinting-active-colors').empty();
	if (isSlider) {
		let $sliderParent = $(selector).parents('.swiper-slide-active');
		$sliderParent.find('.tinting-colors__item').each(function(){
			if ($(this)[0].offsetLeft == left) {
				
				let color = $(this).attr('data-color');
				let name = $(this).attr('data-name');
				let price = $(this).attr('data-price');

				if (!price) {
					price = 50;
				}

				let isActiveClass = $(this).hasClass('tinting-colors__item--active');
				
				$currentTab.find('.tinting-active-colors').append(`
					<div class="tinting-active-colors__item ${isActiveClass ? 'tinting-active-colors__item--active' : ''}" data-color="${color}" data-name="${name}" data-price="${price}">
						<div class="tinting-active-colors__color" style="background-color: ${color}"></div>
						<span>${name}</span>
					</div>

				`);
			}
		});
		
	} else {
		$currentTab.find('.tinting-colors__item').each(function(){
			if ($(this)[0].offsetLeft == left) {
				
				let color = $(this).attr('data-color');
				let name = $(this).attr('data-name');
				let price = $(this).attr('data-price');

				let isActiveClass = $(this).hasClass('tinting-colors__item--active');
				
				$currentTab.find('.tinting-active-colors').append(`
					<div class="tinting-active-colors__item ${isActiveClass ? 'tinting-active-colors__item--active' : ''}" data-color="${color}" data-name="${name}" data-price="${price}">
						<div class="tinting-active-colors__color" style="background-color: ${color}"></div>
						<span>${name}</span>
					</div>

				`);
			}
		});
	}
};

// функция клика по выбранным цветам
const changeColorsFromBottom = (selector) => {

	let name = $(selector).attr('data-name');
	let $currentTab = $(selector).parents('.tinting-tab--active');

	// меняется активность
	$('.tinting-active-colors__item').removeClass('tinting-active-colors__item--active');
	$(selector).addClass('tinting-active-colors__item--active');
	
	// меняется активность сверху	
	$currentTab.find(`.tinting-colors__item`).removeClass('tinting-colors__item--active');
	$currentTab.find(`.tinting-colors__item[data-name="${name}"]`).addClass('tinting-colors__item--active');
	$('.tinting-cart-info__color span').text(name);

	// смена цены
	let price = $(selector).attr('data-price');
	$tintingPrice.text(`${price ? String(price).replace(/(\d)(?=(\d\d\d)+([^\d]|$))/g, '$1 ') : '50'} ₽`);

	// смена цвета
	let color = $(selector).attr('data-color');
	$('html')[0].style.setProperty('--tinting-color', color);
};

// обработчик инпута поиска
let $tintingSearchInput = $('.tinting-search__input');
let $tintingSearch = $('.tinting-search');

$tintingSearchInput.on('focus', function(){
	$(this).parents('.tinting-search').find('.tinting-search__btn').addClass('tinting-search__btn--active');
});

$tintingSearchInput.on('blur', function(){
	$(this).parents('.tinting-search').find('.tinting-search__btn').removeClass('tinting-search__btn--active');
});

let savedTitle = '';

$tintingSearchInput.on('change', function () {

	let self = $(this);

	let searchVal = self.val();

	if (searchVal.length) {
		savedTitle = $('.colors-title').text();
	}

	if (searchVal == '') {
		$('.colors-title').text(savedTitle);
		resetSearch();
	}
});

$tintingSearch.each(function(){
	$(this).on('submit', function(e){
		e.preventDefault();
		if ($(this).find('.tinting-search__input').val().length && $(this).find('.tinting-search__input').val() !== 'Ц301') {
			fakeSearch();
			$('.colors-title').text('Найдено 10 цветов');
		} 
		if ($(this).find('.tinting-search__input').val() == 'Ц301') {
			// $('.colors-top .colors-back').hide();
			$('.colors-top').css('justify-content', 'flex-end');
			$('.colors-top .search-reset').css('display', 'inline-block');
			$('.colors-top .colors-title').hide();
			$('.colors-top .colors-back').hide();
			$('.whited-on-search').addClass('active');
			fakeSearchResultNotFound($(this));
		} 
		if ($(this).find('.tinting-search__input').val() == 'М301') {
			// $('.colors-top .colors-back').hide();
			$('.colors-top').css('justify-content', 'flex-end');
			$('.colors-top .search-reset').css('display', 'inline-block');
			$('.colors-top .colors-title').hide();
			$('.colors-top .colors-back').hide();
			$('.whited-on-search').addClass('active');
			fakeSearchResultNotBase($(this));
			$('html')[0].style.setProperty('--tinting-color', '#ffffff');
		} 

		if (!$(this).find('.tinting-search__input').val().length) {
			resetSearch();
		}
	});
});

// $tintingSearch

const resetSearch = () => {
	$('[data-tinting-tar="choice"]').trigger('click');
	$('.whited-on-search').removeClass('active');
	$('.colors-top .search-reset').hide();
	$tintingSearchInput.val('');
	$('.colors-top').css('justify-content', 'space-between');
	$('.colors-top .colors-title').show();
};

$('.search-reset').on('click', resetSearch);

const fakeSearch = () => {
	let activeTab = $('.tinting-tab--active');
	if (activeTab.hasClass('tinting-tab--first') || activeTab.attr('data-tinting-tab') == 'cart' || activeTab.attr('data-tinting-tab') == 'not-found' || activeTab.attr('data-tinting-tab') == 'not-base') {
		$('.tinting-tab').removeClass('tinting-tab--active');
		$('[data-tinting-tar="colorise1"]').trigger('click');
	}
	$('.colors-top .colors-back').hide();
	$('.colors-top').css('justify-content', 'space-between');
	$('.colors-top .search-reset').css('display', 'inline-block');
	$('.whited-on-search').addClass('active');
	$('.colors-top .colors-title').show();
};

const fakeSearchResultNotFound = (activeSelector) => {
	let val = activeSelector.find('.tinting-search__input').val();
	$('.tinting-not-found__title span').text(val);
	$('.tinting-tab').removeClass('tinting-tab--active');
	$('[data-tinting-tab="not-found"]').addClass('tinting-tab--active');
};

const fakeSearchResultNotBase = (activeSelector) => {
	let val = activeSelector.find('.tinting-search__input').val();
	$('.tinting-not-base__title span').text(val);
	$('.tinting-tab').removeClass('tinting-tab--active');
	$('[data-tinting-tab="not-base"]').addClass('tinting-tab--active');
};

/* colors-top переписать с css на классы в условиях */


$(window).on('resize', function(){
	changeWords2()
});

function changeWords2() {
	const scrollWidth = parseInt(window.innerWidth - document.body.offsetWidth);
	if ($(window).width() > 992 - scrollWidth) {
		$('.tinting-bottom__to-cart span').text('Добавить в корзину');
		$('.order-tinting-modal__btns .main-btn').text('Перейти в корзину');
		$('.colors-back span').text('Выбрать цветовую группу');
		$('.tinting-cart-btns .tinting-bottom__to-cart span').text('Перейти в корзину');
		$('.search-reset span').text('Сбросить результаты поиска');
		$('.tinting-cart-btns .colors-back span').text('Начать сначала');
		$('.tinting-reset').text('Сбросить фильтры');
		$('.tinting-colorise__caption').text('В данный цвет можно заколеровать 114 товаров');
	} else {
		
		$('.tinting-bottom__to-cart span').text('В корзину');
		
		//$('.colors-back span').text('Назад');
		
		$('.tinting-cart-btns .colors-back span').text('Начать сначала');
		$('.tinting-reset').text('Сбросить');
		$('.order-tinting-modal__btns .main-btn').text('Перейти в корзину');
	}

	if ($(window).width() >= 768 && $(window).width() < 992 - scrollWidth) {
		$('.search-reset span').text('Сбросить');
		$('.colors-back span').text('Назад');
		$('.tinting-bottom__to-cart span').text('Добавить в корзину');
		$('.tinting-cart-btns .tinting-bottom__to-cart span').text('Перейти в корзину');
		$('.tinting-cart-btns .colors-back span').text('Начать сначала');
	}

	if ($(window).width() <= 768) {
		$('.colors-back span').text('Назад');
		$('.tinting-cart-btns .colors-back span').text('Начать сначала');
		$('.search-reset span').text('Сбросить');
		$('.tinting-colorise__caption').text('Можно заколеровать 114 товаров');
		$('.tinting-cart-btns .tinting-bottom__to-cart span').text('Перейти в корзину');
		$('.colors-top .search-reset span').text('Сбросить результаты поиска');
	}
}

changeWords2()

function changeSlides() {
	const scrollWidth = parseInt(window.innerWidth - document.body.offsetWidth);
	if ($(window).width() < 992 - scrollWidth) {
		let elements = $('.tinting-colors__item');
		let selectLine = $('.select-line');
		if (elements.parents('.grid-items')) {
			elements.unwrap();
			selectLine.remove();
			if (elements.parents('.swiper-slide')) {
				elements.unwrap();

				$('.tinting-colors').each(function(){
					let items = $(this).find('.tinting-colors__item');

					for(var i = 0; i < items.length; i += 36) {
						items.slice(i, i + 36).wrapAll("<div class='grid-items'></div>");
					}

					$(this).find('.grid-items').wrap('<div class="swiper-slide"></div>');
		
				});
				setTimeout(() => {
					$('.colors-slider .swiper-slide').prepend('<div class="select-line"></div>');
				}, 200);
				
				
			}
		}
	}
}

changeSlides();


$('.anchor').on('click', function(e){
	let href = $(e.currentTarget).attr('href');

	let offset = $(href).offset().top;

	$('html, body').animate({
		scrollTop: offset,
	}, 500);
});


$('.custom-select-colorise__input').click(function (e) {
	if (!$(this).parents('.custom-select-colorise').hasClass('is-open')) {
		$('.custom-select-colorise').removeClass('is-open');
		$(this).parents('.custom-select-colorise').addClass('is-open');
	} else {
		$(this).parents('.custom-select-colorise').removeClass('is-open');
	}
});

// когда кликаем вне селекта - скрываем и сбрасываем
$(document).mouseup(function (e) { // событие клика по веб-документу
	let select = $('.custom-select-colorise'); // тут указываем ID элемента
	if (!select.is(e.target) && select.has(e.target).length === 0) { // и не по его дочерним элементам
		select.removeClass('is-open');
	}
});

// клик по элементам селекта
$('.custom-select-colorise__dropdown li').click(function () {
	$(this).parents('.custom-select-colorise').find('.custom-select-colorise__dropdown li').removeClass('is-active');

	let $parent = $(this).parents('.custom-select-colorise');

	$(this).addClass('is-active');
	$parent.find('.custom-select-colorise__input').text($(this).text());

	$parent.removeClass('is-open');
});

// filter
(function(){
	/* 
		1. клик по палитре - оставляет товары только по выбранной базе (А или С). При этом клик по палитре сбрасывает все фильтры ниже.
		2. группы товаров хранятся заранее скрытые и пустые.
		3. товары заворачиваются в группы по data-group.
		4. после создания групп изменяются табы (считается число, формируется первый таб и остальные табы с числами)
		5. фильтрация групп. фильтруются все группы, при этом табы тоже меняются в зависимости от условий
		6. смена табов работает внутри фильтра
	
	*/

	let initialBase;
	let initialProducts = $('.tinting-filtered').html();

	const $tintingTabBtn = $('.tinting-tab-cross');
	$tintingTabBtn.on('click', (e) => {
		
		if ($('.tinting-filtered')) {
			let activeData = $(e.currentTarget).attr('data-tinting-tar');
			let activeElement = $(`[data-tinting-tab="${activeData}"]`);
			let firstActive = activeElement.find('.tinting-colors__item.tinting-colors__item--active:first-child')[0];
			let self = $(firstActive);
			if (self.attr('data-base')) {
				let base = self.attr('data-base');
				initialBase = base;
				resetSelectFilters();
				$('.tinting-content').addClass('active');
				resetFiltersToGroup();
				filterToGroups(base);
				makingTabsGroups(initialBase);

				let activeCustomer = $('.tinting-filtered-tabs .tinting-filtered-tabs__item:first-child .tinting-filtered-tabs__btn').attr('data-tab');
				$('.tinting-filtered-item').hide();
				$(`.tinting-filtered-item[data-customer="${activeCustomer}"]`).show();

				countItems();
				const scrollWidth = parseInt(window.innerWidth - document.body.offsetWidth);
				if ($(window).width() < 992 - scrollWidth) {
					$('.filt-item__product .image-switch:visible').each(function () {
						let th = $(this);
						th.slick({
							arrows: false,
							dots: true,
							infinite: false,
						});
					});
				}
			}
		}
	});

	$('.tinting-colors__item').on('click', (e) => {
		let self = $(e.currentTarget);
		if (self.attr('data-base')) {
			let base = self.attr('data-base');
			initialBase = base;
			resetSelectFilters();
			$('.tinting-content').addClass('active');
			resetFiltersToGroup();
			filterToGroups(base);
			makingTabsGroups(initialBase);

			let activeCustomer = $('.tinting-filtered-tabs .tinting-filtered-tabs__item:first-child .tinting-filtered-tabs__btn').attr('data-tab');
			$('.tinting-filtered-item').hide();
			$(`.tinting-filtered-item[data-customer="${activeCustomer}"]`).show();

			countItems();

			if ($(window).width() <= 991) {
				$('.filt-item__product .image-switch:visible').each(function () {
					let th = $(this);
					th.slick({
						arrows: false,
						dots: true,
						infinite: false,
					});
				});
			}
		}
	});

	function declOfNum(number, titles) {  
		let cases = [2, 0, 1, 1, 1, 2];  
		return titles[ (number%100>4 && number%100<20)? 2 : cases[(number%10<5)?number%10:5] ];  
	}

	const countItems = () => {
		$('.filt-item:visible').each(function(){
			let products = $(this).find('.filt-item__products');
			let titleCounter = $(this).find('.filt-item__title span');
			titleCounter.text(`(${products.children().length} ${declOfNum(products.children().length, ['товар', 'товара', 'товаров'])})`);
		});
	}

	const hideNotChildrenItems = () => {
		$('.tinting-filtered-item').each(function(){
			if ($(this).find('.filt-item__products').children().length === 0) {
				$(this).hide();
			}
		});
	};

	const resetFiltersToGroup = () => {
		filters = {};
		filtered = '';
		$('.tinting-filtered-item').show();
		hideNotChildrenItems();

		$('.tinting-filtered').empty();
		$('.tinting-filtered').html(initialProducts);
	};

	const filterToGroups = (base) => {
		$('.tinting-filtered .product').hide();
		$(`.tinting-filtered .product[data-base="${base}"]`).show();

		$(`.tinting-filtered .product[data-base="${base}"]`).each(function(){
			let group =  $(this).attr('data-group');
			let customer = $(this).attr('data-customer');
			
			$(`.tinting-filtered-item[data-group="${group}"]`).show();
			$(`.tinting-filtered-item[data-group="${group}"][data-customer="${customer}"]`).find('.filt-item__products').append($(this));
		});

		hideNotChildrenItems();
		
		showHide();
		countItems();
		sliders();

		

	};


	const makingTabsGroups = (base) => {
		let visibleItems = $(`.tinting-filtered .product:visible`);
		let tabsObject = {};
		visibleItems.each(function(i){
			let customer = $(this).attr('data-customer');

			tabsObject[customer] = 0;
		});

		$('.tinting-filtered-tabs').empty();
		for (let item in tabsObject) {
			$('.tinting-filtered-tabs').append(`<li class="tinting-filtered-tabs__item"><button class="tinting-filtered-tabs__btn" data-tab="${item}">${item} <span>${$(`[data-customer=${item}][data-base="${initialBase}"]:visible`).length}</span></button></li>`);
		}

		setTimeout(() => {
			if ($(window).width() < 768) {
				$('.tinting-filtered-tabs').mCustomScrollbar({
					mouseWheelPixels: 300,
					scrollInertia: 300,
				});
				//new SimpleBar($('.tinting-filtered-tabs')[0]);
			}
		}, 1000);
		

		$('.tinting-filtered-tabs .tinting-filtered-tabs__item:first-child .tinting-filtered-tabs__btn').addClass('tinting-filtered-tabs__btn--active');


		const firstItemHTML = $('.tinting-filtered-tabs__item:first-child .tinting-filtered-tabs__btn').html();
		console.log(firstItemHTML)

		$('.tinting-filtered-select-mobile__input').html(firstItemHTML);

		$('.filt-item__internal:visible').each(function(){
			let offsetLeft = $(this).offset().left - $(this).parent().offset().left;
			let offsetTop = $(this).offset().top - $(this).parent().offset().top;

			if (offsetLeft == 0 && offsetTop > 0) {
				$(this).prev().addClass('wrap');
				$(this).addClass('wrap-left');
			}
		});
	};


	function concatValues(obj) {
		var value = '';
		for ( var prop in obj ) {
			value += obj[ prop ];
		}
		return value;
	}
  
  let filters = {},
	filterValue = '',
	filtered = '';
  
  $('.custom-select-colorise__dropdown li').on('click',  function(e){
		e.preventDefault();
		let $button = $(e.currentTarget);
		let $buttonParent = $button.parents('ul');
		let filterGroup = $buttonParent.attr('data-filter-group');
		filters[filterGroup] = $button.attr('data-filter');
		filterValue = concatValues(filters);
		filtered = $('.tinting-filtered-item').filter(filterValue);
		$('.tinting-filtered-item').hide();
		filtered.show();
		$('.tinting-reset').addClass('reset-active');
		
		makingTabsGroups(initialBase);

		$('.tinting-filtered-tabs .tinting-filtered-tabs__item:first-child .tinting-filtered-tabs__btn').addClass('tinting-filtered-tabs__btn--active');
		let activeCustomer = $('.tinting-filtered-tabs .tinting-filtered-tabs__item:first-child .tinting-filtered-tabs__btn').attr('data-tab');
		if (!filtered) {
			$('.tinting-filtered-item').hide();
			$(`.tinting-filtered-item[data-customer="${activeCustomer}"]`).show();

			hideNotChildrenItems();
			countItems();
		} else {
			$('.tinting-filtered-item').hide();
			filtered.each(function(){
				if ($(this).attr('data-customer') == activeCustomer) {
					$(this).show();
				}
			});

			hideNotChildrenItems();
			countItems();
		}
		
	});
  
  $('.tinting-reset').click(function(e){
		e.preventDefault();
		resetSelectFilters();
	});

	const resetSelectFilters = () => {
		filters = {};
		filtered = '';

		$('.first-select-colorise').find('.custom-select-colorise__input').text('Выберите из списка');
		$('.first-select-colorise').find('.custom-select-colorise__dropdown li').removeClass('is-active');

		$('.second-select-colorise').find('.custom-select-colorise__input').text('Выберите из списка');
		$('.second-select-colorise').find('.custom-select-colorise__dropdown li').removeClass('is-active');

		resetFiltersToGroup();
		filterToGroups(initialBase);
		makingTabsGroups(initialBase);

		$('.tinting-reset').removeClass('reset-active');

		let activeCustomer = $('.tinting-filtered-tabs .tinting-filtered-tabs__item:first-child .tinting-filtered-tabs__btn').attr('data-tab');
		$('.tinting-filtered-item').hide();
		$(`.tinting-filtered-item[data-customer="${activeCustomer}"]`).show();
	};
	

	$('body').on('click', '.tinting-filtered-tabs__btn', function(){
		$('.tinting-filtered-tabs__btn').removeClass('tinting-filtered-tabs__btn--active');
		$(this).addClass('tinting-filtered-tabs__btn--active');
		let tab = $(this).attr('data-tab');

		if (!filtered) {
			$('.tinting-filtered-item').hide();
			$(`.tinting-filtered-item[data-customer="${tab}"]`).show();

			hideNotChildrenItems();
			countItems();
		} else {
			$('.tinting-filtered-item').hide();
			filtered.each(function(){
				if ($(this).attr('data-customer') == tab) {
					$(this).show()
				}
			});

			hideNotChildrenItems();
			countItems();
		}

		if ($(window).width() <= 992) {
			$('.filt-item__product .image-switch:visible').each(function () {
				let th = $(this);
				console.log(th)
				//th.slick('setPosition');
				// $(th[0]).slick('reinit');
				if (!th.hasClass('slick-initialized')) {
					th.slick({
						arrows: false,
						dots: true,
						infinite: false,
					});
				}
				
			});
		}

		if ($(window).width() <= 768) {
			const thisText = $(this).html();
			console.log(thisText)
			$('.tinting-filtered-select-mobile__input').html(thisText);
			$('.tinting-filtered-select-mobile').removeClass('is-open');
		}
		
	});
})();

function sliders() {
	$('.filt-item__product .image-pagination').empty();

	setTimeout(function () {
		$('.filt-item__product .image-switch-item img').each(function () {
			if ($(this).attr('src') == '#') {
				$(this).attr('src', $(this).attr('data-src'));
			}
		});
	}, 500);


	if ($(window).width() <= 991) {
		// if ($('.filt-item__product .image-switch')) {
		// 	let slider = $('.filt-item__product .image-switch').slick({
		// 		arrows: false,
		// 		dots: true,
		// 		infinite: false,
		// 	});
		// }
		
		// setTimeout(() => {
			
		// }, 300);

		// setTimeout(() => {
		// 	$('.filt-item__product .image-switch').slick('setPosition');
		// }, 300);


		// $('.filt-item__product .image-switch').on('beforeChange', function (event, slick, currentSlide, nextSlide) {
		// 	let length = $(this).attr('data-length');

		// 	if (length > 6) {
		// 		if ((nextSlide == ((length - (length - 6)) - 1))) {
		// 			$(this).parents('.product__image-wrapper').addClass('more-photos').addClass('active');
		// 		} else {
		// 			$(this).parents('.product__image-wrapper').removeClass('more-photos').removeClass('active')
		// 		}
		// 	}
		// });

		// $('.filt-item__product .image-switch').on('touchstart', () => {
		// 	$('.filt-item__product .viewed-slider').addClass('swiper-no-swiping');
		// });

		// $('.filt-item__product .image-switch').on('afterChange', function (event, slick, direction) {
		// 	$('.filt-item__product .viewed-slider').removeClass('swiper-no-swiping');
		// });
	}

	// задаем всем свитчам на странице индексы
	$('.filt-item__product .image-switch').each(function () {
		let itemsLength = $(this).find('.image-switch-item').length;
		let forMoreLength = $(this).find('.image-switch-item:not(.slick-cloned)').length;

		if (itemsLength <= 1) {
			$(this).parents('.product__image-wrapper').find('.image-pagination').hide();
		}

		for (let i = 0; i < itemsLength; i++) {
			$(this).find('.image-switch-item').eq(i).attr('data-index', i);
			if (i <= 5) {
				$(this).parents('.product__image-wrapper').find('.image-pagination').append('<div class="image-pagination-item" data-index="' + i + '"></div>');
				$(this).parents('.product__image-wrapper').find('.image-pagination').find('.image-pagination-item').eq(0).addClass('image-pagination-item-active');
			}
		}

		if (forMoreLength > 6) {
			$(this).parents('.product__image-wrapper').addClass('more-photos');

			let l = itemsLength - 6;
			$(this).parents('.product__image-wrapper').attr('data-more', `Ещё ${l} фото`);
		}
	});

	// переключение свитчей по наведению
	$('.filt-item__product .image-switch-item').on('mouseover', function () {
		let i = $(this).attr('data-index');
		$(this).parents('.product__image-wrapper').find('.image-pagination').find('.image-pagination-item').removeClass('image-pagination-item-active');
		$(this).parents('.product__image-wrapper').find('.image-pagination').find('.image-pagination-item[data-index="' + i + '"]').addClass('image-pagination-item-active');

		if ($(this).attr('data-index') == 5) {
			$(this).parents('.more-photos').addClass('active');
		}
	});

	// переключение свитчей по наведению
	$('.filt-item__product .image-switch-item').on('mouseout', function () {
		$(this).parents('.product__image-wrapper').find('.image-pagination').find('.image-pagination-item').removeClass('image-pagination-item-active');
		$(this).parents('.product__image-wrapper').find('.image-pagination').find('.image-pagination-item[data-index="0"]').addClass('image-pagination-item-active');
		$('.more-photos').removeClass('active');
	});
}

function showHide() {
	$('.tinting-filtered-item').each(function(){
	let flag = 0;
	let btn = $(this).find('.show-products');
	let parent = $(this);
	btn.on('click', function(){
		if (flag == 0) {
			parent.addClass('open-products');
			btn.addClass('op');
			btn.text('Скрыть товары');
			flag = 1;
		} else {
			parent.removeClass('open-products');
			btn.removeClass('op');
			btn.text('Показать товары');
			flag = 0;
		}
	});
});

}

$('.tinting-filtered-select-mobile__input').on('click', function(){
	if (!$(this).parents('.tinting-filtered-select-mobile').hasClass('is-open')) {
		$(this).parents('.tinting-filtered-select-mobile').addClass('is-open')
	} else {
		$(this).parents('.tinting-filtered-select-mobile').removeClass('is-open')
	}
});

$('body').on('mouseenter', '.tinting-colors__item', function(){
	let name = $(this).attr('data-name');
	$(`.tinting-active-colors__item[data-name='${name}']`).addClass('hover-color');
});

$('body').on('mouseleave', '.tinting-colors__item', function(){
	let name = $(this).attr('data-name');
	console.log('name');
	$(`.tinting-active-colors__item`).removeClass('hover-color');
});

$('body').on('mouseenter', '.tinting-active-colors__item', function(){
	let name = $(this).attr('data-name');
	$(`.tinting-colors__item[data-name='${name}']`).addClass('hover-color-top');
});

$('body').on('mouseleave', '.tinting-active-colors__item', function(){
	let name = $(this).attr('data-name');
	console.log('name');
	$(`.tinting-colors__item`).removeClass('hover-color-top');
});

$(document).mouseup(function (e) { // событие клика по веб-документу
	let select = $('.tinting-filtered-select-mobile '); // тут указываем ID элемента
	if (!select.is(e.target) && select.has(e.target).length === 0) { // и не по его дочерним элементам
		select.removeClass('is-open');
	}
});